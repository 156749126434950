import { BreakingGroupOptions } from '@hubcms/domain-storyblock';
import { CreateStoryblockOptionsFn } from '../../domain/create-storyblock-options-fn';
import { mapToGroupOptionsRecord } from '../../lib/mapToGroupOptionsRecord';
import { getIsHiddenOnMobile } from '../getIsHiddenOnMobile';

export const createBreakingGroupOptions: CreateStoryblockOptionsFn<BreakingGroupOptions> = ({ group }) => {
  const groupOptions = mapToGroupOptionsRecord(group.groupOptions);

  return {
    type: 'BreakingGroup',
    isHiddenOnMobile: getIsHiddenOnMobile(groupOptions),
  };
};
