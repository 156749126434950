import { brandCode } from '@hubcms/brand';

import { warn } from '@hubcms/utils-monitoring';
import { PageType } from '@hubcms/domain-tracking';
import { getRecommendations } from '@hubcms/data-access-capitan';
import { RecommendationsRequest, RecommendationsListRequest, RecommendationsResponse } from '@hubcms/domain-capitan';
import { getUserRegioSelection } from '@hubcms/utils-my-section';
import { PersonalizedList } from '../domain/personalized-list';

interface RecommendationsListsParams {
  apiEndpoint: string;
  personalizedArticleLists: PersonalizedList[];
  identityAccountId: string | null;
  pageType: PageType;
  articleId: string | null | undefined;
  clientId: string;
  viewId: string;
  excludes: string[];
}

const determineIsPremium = (premiumStatus: string | null): boolean | null => {
  switch (premiumStatus) {
    case 'Premium':
      return true;
    case 'Free':
      return false;
    default:
      return null;
  }
};

export const getRecommendationData = async (params: RecommendationsListsParams): Promise<RecommendationsResponse> => {
  const {
    apiEndpoint: recommendationsApiEndpoint,
    personalizedArticleLists,
    identityAccountId,
    pageType,
    articleId,
    clientId,
    viewId,
    excludes,
  } = params;

  // compose request
  const listRequests = personalizedArticleLists.map(({ title, params }): RecommendationsListRequest => {
    const enableRegionFilter = params.personalizationModel === 'regional';
    const userCommunityIdentifier = enableRegionFilter ? getUserRegioSelection()?.communityIdentifier : null;

    return {
      name: title,
      size: params.maxCount ?? 0,
      isPremium: determineIsPremium(params.premiumStatus),
      ...(params.typesArray?.length
        ? {
            articleContentTypes: params.typesArray,
          }
        : {
            articleContentType: params.typeSelection ?? '',
          }),
      enableRegionFilter,
      ...(enableRegionFilter &&
        userCommunityIdentifier && {
          userZipCodes: {
            declared: [userCommunityIdentifier],
          },
        }),
    };
  });

  const hasExcludedIds = excludes.length > 0;

  const recommendationsRequest: RecommendationsRequest = {
    clientId,
    viewId,
    identityAccountId,
    brandCode,
    pageType,
    articleId,
    application: 'www',
    lists: listRequests,
    ...(hasExcludedIds && { excludes }),
  };

  // launch request
  try {
    const response = await getRecommendations(recommendationsApiEndpoint, recommendationsRequest);
    return response;
  } catch (error) {
    warn('Error fetching recommendations.', error as never, recommendationsRequest);
    return {
      requestId: '',
      lists: [],
      modelVersion: '',
      recommender: '',
    };
  }
};
