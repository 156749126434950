import type { GGrid, GLegacyTeaser } from '@hubcms/domain-teaser-grid';
import type { BreakingGroupOptions } from '@hubcms/domain-storyblock';

import { defaultTeaserImageRatio } from '@hubcms/brand';
import { isArticleTeaserData } from '@hubcms/domain-teaser';
import type { MapStoryblockFn } from '../../domain/map-storyblock-fn';

const getPreferredImageFormat = (type: string, isHeadTeaser: boolean) => {
  if (type === 'podcast') {
    return 'oneOne';
  }
  return isHeadTeaser ? 'sixteenNine' : defaultTeaserImageRatio;
};

export const mapBreakingGroup: MapStoryblockFn<BreakingGroupOptions> = (storyblockOptions, teaserAreas) => {
  const numberOfTeasers = 4;

  const items: Array<GLegacyTeaser | GGrid> = teaserAreas.groupArea
    .filter(isArticleTeaserData)
    .slice(0, numberOfTeasers)
    .map((teaserData, idx) => {
      const isHeadTeaser = idx === 0;
      return {
        type: 'legacy-teaser',
        data: {
          teaserData,
          imageFormat: getPreferredImageFormat(teaserData.contentType, isHeadTeaser),
          options: {
            sm: isHeadTeaser ? 'breaking' : 'breaking-slider',
            hideIntro: true,
            isPriority: isHeadTeaser,
          },
          trackingData: {
            ...teaserData.trackingData,
            clickitemposition: idx + 1,
          },
        },
        gridProps: {
          minWidth: { sm: '280px' },
        },
      };
    });

  return {
    key: 'groupArea',
    gridColumns: 1,
    gridColumnsMd: 1,
    gridColumnsLg: 1,
    gridAutoRows: 'min-content',
    hasRowGap: false,
    hasInlinePadding: false,
    hasNoGridDivider: true,
    items: [items[0]]
      .concat(
        items.length === 2 ? [{ ...items[1], gridProps: { ...items[1].gridProps, displayMd: 'none', displayLg: 'none' } }] : [],
      )
      .concat(items.length >= 2 ? createEvolvingStoriesGrid(items.slice(1)) : []),
  };
};

function createEvolvingStoriesGrid(items: GGrid['data']['items']): GGrid {
  const singleBottomItem = items.length === 1;
  return {
    type: 'grid',
    data: {
      items,
      gridColumns: items.length,
      gridColumnsMd: singleBottomItem ? 3 : items.length,
      gridColumnsLg: 3,
      scroll: {
        hasPadding: true,
        breakpoints: {
          xs: true,
          sm: true,
          md: false,
          lg: false,
          xl: false,
        },
      },
      hasInlinePadding: false,
      hasNoGridDivider: true,
      theme: 'breaking', // CW-625 could be a new theme?
    },
    gridProps: {
      column: '1 / -1',
      columnMd: '1 / -1',
      columnLg: '1 / -1',
      display: singleBottomItem ? 'none' : 'block',
      displayMd: 'block',
    },
  };
}
